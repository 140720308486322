$primary-light: #f4f4f4;
$secondary-light: #ced4da;
$secondary-light: #ced4da;
$primary-dark: #282c34;
$primary-blue: #1971c2;
$secondary-blue: #228be6;
$primary-green: #0e9e1c;
$secondary-green: #0a7e14;
$primary-red: #e03131;
$secondary-red: #fa5252;
$primary-yellow: #fcc419;

$icon-width: 12px;
%fixed-layout {
  max-width: 35rem;
}

%centered-position{
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

%button-shared {
  background-color: $secondary-blue;
  border: none;
  border-radius: 4px;
  color: $primary-light;
  &:hover{
    cursor: pointer;
    background-color: $primary-blue;
  }
}

%card-shared {
  padding: 4px 8px;
  border-radius: 4px;
  background-color: $primary-light;
  box-shadow: 0 0 2px 0 $primary-dark;
}

.App {
  background-color: $primary-dark;
  min-height: 100vh;
  text-align: center;
  box-sizing: border-box;
  overflow-x: visible;

  a {
    text-decoration: none;
    color: $secondary-blue;
  }

  button[disabled] {
    background-color: $secondary-light;
    &:hover {
      cursor: not-allowed;
    }
  }

  header {
    background-color: $secondary-blue;
    padding-block: 4px;
    .wrapper {
      @extend %fixed-layout;
      margin-inline: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 50%;
      }

      a, button {
        @extend %button-shared;
        height: fit-content;
        padding: 8px;
        color: $primary-light;
        &:hover {
          font-weight: bold;
        }
      }

      button{
        &:hover {
          color: $secondary-red;
          background-color: $primary-light;
        }
      }

      .title-wrapper{
        display: flex;
        gap: 4px;
        align-items: center;
        h3 {
          margin: 0;
          color: $primary-light;
        }
      }
    }    
  }

  .error {
    color: $primary-red;
  }

  .success {
    color: $primary-green;
  }

  .disable{
    background-color: $secondary-light;
  }

  .not-found {
    color: $primary-light;
    font-size: 2rem;
    margin-top: 2rem;
    a {
      @extend %button-shared;
      padding: 4px 8px;  
    }
  }

  .auth-form {
    @extend %card-shared;
    @extend %centered-position;
    width: 20rem;
    max-width: 20rem;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: fixed;

    .input-group {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      input {
        padding: 8px;
        border: none;
        border-radius: 4px;
        outline: none;
      }
    }

    button {
      @extend %button-shared;
      width: 100%;
      padding: 8px;
    }

    .back-home-btn {
      position: absolute;
      top: 4px;
      left: 8px;
      color: $primary-dark;
      :hover {
        cursor: pointer;
      }
    }
  }

  .container{
    @extend %fixed-layout;
    overflow-x: visible;
    margin-inline: auto;

    .todo-form{
      width: 100%;
      margin-top: 1rem;
      display: flex;
      gap: 0;
  
      .input-clearable{
        width: 100%;
        display: flex;
        background-color: $primary-light;
        align-items: center;
        position: relative;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        overflow: hidden;
  
        input {
          width: 100%;
          border: none;
          outline: none;
          padding: 8px; /* Added padding for better appearance */
        }
        span{
          position: absolute;
          top: 50%;
          right: 0;
          background-color: fff;
          transform: translateY(-50%);
          cursor: pointer;
          width: 1rem;
        }
      }    
  
      button{
        @extend %button-shared;
        width: 5rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  
    .toolbar {
      @extend  %card-shared;
      margin-top: 1rem;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: solid 1px $secondary-light;
      z-index: 1;
      text-align: left;
      display: flex;
      gap: 4px;
      align-items: center;

      button{
        @extend %button-shared;
        padding: 4px;
        width: 25px;
        &:first-child{
          background-color: $secondary-green;
        }
      }

      select {
        border-radius: 4px;
        outline: none;
        border: solid 1px $secondary-light;
        height: 100%;
        padding: 4px;
        option {
          padding: 4px;
        }
      }

    }

    .todo-list {
      @extend %card-shared;
      height: 75vh;
      padding-top: 1rem;
      overflow-y: scroll;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 4px;
      box-shadow: none;
      background-color: $primary-light;
      padding: 4px 8px;
  
      .todo-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 8px;
        margin: 10px 0;
        border-radius: 5px;
        border: solid 1px #ccc;
        background-color: $primary-light;
        position: relative;
        gap: 4px;
  
        .input-title{
          border: none;
          outline: none;
          width: 100%;
          max-width: 15rem;
          border-radius: 4px;
          padding: 4px;
          height: 100%;
        }
  
        .title{
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: left;
          position: relative;          
        }      
        
        .list-group{
          display: flex;
          gap: 4px;
          align-items: center;
          &:hover {
            cursor: pointer;
          }
        } 

        .list-button{
          padding-inline: 4px;
          &:hover {
            cursor: pointer;
            + .action{
              display: flex;
            }
          }
        }

        .action{
          display: none;
          position: absolute;
          padding: 0.5rem;
          border-radius: 4px;
          background-color: $primary-light;
          // border: solid 1px $primary-dark;
          transform: translateY(92%);
          bottom: 0;
          right: 0;
          z-index: 10;
          box-shadow: 0 0 2px 0 $primary-dark;

          flex-direction: column;
          gap: 4px;
          &:hover{
            display: flex;
          }

          span {
            color: $primary-dark;
            font-size: 12px;
          }

          button {
            @extend %button-shared;
            padding: 2px 4px;
            text-align: left;

            i {
              width: $icon-width;
            }

            &.incomplete
            {
              background-color: $secondary-light;
              color: $primary-dark;
              &:hover{
                background-color: $primary-light ;
                outline: solid 1px $secondary-light;
              }
            }

            &.completed
            {
              background-color: $primary-green;
              &:hover{
                background-color: $secondary-green;
              }
            }

            &.danger{
              background-color: $primary-red;
              &:hover{
                background-color: $secondary-red;
              }
            }
          }
        }
  
        &.todo-item:hover {
          border: none;
          border: solid 1px $primary-blue;
          box-shadow: 0 0 2px 0 $primary-blue;
        }
        
        &.completed {
          text-decoration: line-through;
          color: $primary-light;
          background-color: $primary-green;
        }
        
        &.in-progress {
          color: $primary-light;
          background-color: $secondary-blue;
        }
      }

      &::-webkit-scrollbar {
        width: 5px;
      }
      
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-bottom-right-radius: 4px;
      }
      
      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 8px;
      }
      
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }
}

.backdrop{
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #282c34c9;
  z-index: 100;

  .confirm-popup {
    @extend %card-shared;
    position: fixed;
    width: max-content;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    transform: translate(-50%, -50%);
    padding: 8px;
    gap: 8px;
    text-align: left;

    i {
      color: $primary-yellow;
    }

    .action {
      text-align: right;
      button {
        @extend %button-shared;
        padding: 4px;
        margin: 4px;
        width: 5rem;
        &:first-child{
          background-color: $primary-red
        }
      }
    }
  }
}
